import * as React from "react";
import { PureComponent } from "react";
import "@themes/layout.scss";

export interface RowProps {
	children:
		| React.ReactElement<any>[]
		| React.ReactElement<any>
		| JSX.Element
		| JSX.Element[]
		| (JSX.Element[] | JSX.Element)[];
	style?: React.CSSProperties;
	className?: string;
}

export default class Row extends PureComponent<RowProps> {
	render() {
		const { children, className, style } = this.props;
		return (
			<div className={["row", className].join(" ")} style={style}>
				{children}
			</div>
		);
	}
}
