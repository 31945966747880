import * as React from "react";
import { PureComponent } from "react";
import styled from "styled-components";
import "@themes/layout.scss";

export interface ColumnProps {
	children:
		| React.ReactElement<any>[]
		| React.ReactElement<any>
		| JSX.Element
		| JSX.Element[]
		| (JSX.Element[] | JSX.Element)[];
	style?: React.CSSProperties;
	className?: string;
}

export default class Column extends PureComponent<ColumnProps> {
	render() {
		const { children, className, style } = this.props;
		return (
			<div className={["column", className].join(" ")} style={style}>
				{children}
			</div>
		);
	}
}

export const LargerColumn = styled(Column)`
	flex: 2.2;
	@media (max-width: 854px) {
		flex: 1.5;
	}
	@media (max-width: 712px) {
		flex: 1;
	}
`;
